<template>
  <div class="container">
    <div class="row g-4 mt-4 mb-5 m-2">
      <div class="col-md-4">
        <!-- <img src="yasaman.jpg" class="img-fluid" alt="Yasaman Rafat, PhD" /> -->
        <img src="raw/yasaman.jpg" class="img-fluid" alt="Yasaman Rafat, PhD" width="350" />
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h4 class="card-title mb-4">
            Welcome to Dr. Yasaman Rafat’s Second Language Acquisition and Multilingualism Lab!
          </h4>

          <p class="card-text fs-5 mb-4">
            Our research focuses on speech learning, attrition and change in both child and adult second
            language/dialect learners, bilinguals, and multilinguals. Our research is interdisciplinary, where we
            consider the role of input (auditory, orthographic and visual), as well as cognitive and social factors. We
            work on different languages including Spanish, English, Farsi, Arabic, Italian, and are open to working on
            other languages.
          </p>

          <p class="card-text fs-5">
            Our research is collaborative and we employ different methodologies to analyze speech production and
            perception in different populations. These experimental techniques include acoustic analysis, eye-tracking,
            and neuroscientific methods.
          </p>

          <button class="btn btn-primary btn-block mb-3" type="button" @click.prevent="redirect_to_team()">
            Meet Our Team!
          </button>
          <!--
          <h5 class="card-title">Associate Professor of Hispanic Studies (Linguistics) at Western University</h5>

          <p class="card-text">
            <ul>
              <li>
                Associate member of <a href="https://www.uwo.ca/bmi/" target="_blank">
                  The Brain & Mind Institute <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                Affiliated with <a href="https://www.uwo.ca/linguistics/" target="_blank">
                  Linguistics <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                Affiliated with <a href="https://www.migration.uwo.ca/people/index.html"  target="_blank">
                  Migration and Ethnic Studies <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </p>

          <h5 class="card-title">Research Interests</h5>
          <p class="card-text">
            <ul>
              <li>
                Second language acquisition of phonetics and phonology
              </li>
              <li>
                Bilingualism and phonological attrition
              </li>
              <li>
                Socio-phonetics and sound change
              </li>
            </ul>
          </p>

          <h5 class="card-title">Education</h5>
          <p class="card-text">
            <ul>
              <li>
                2011, Ph.D. in Hispanic Linguistics, University of Toronto
              </li>
              <li>
                1999, M.A. in Hispanic Linguistics, University of Toronto
              </li>
              <li>
                1998, Honours B.A. in Spanish and Italian Languages, University of Toronto
              </li>
            </ul>
          </p>

          <h5 class="card-title mb-2">Publications</h5>
          <p class="card-text">
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <a href="https://scholar.google.com/citations?hl=en&amp;user=pMyR0uAAAAAJ" target="_blank" title="Google Scholar"><img alt="Google Scholar" class="social-icon" src="https://www.uwo.ca/web_standards/img/social/svg/icn-googlescholar.svg" width="32px"></a>
            &nbsp;
            <a href="https://orcid.org/0000-0002-8983-9405" target="_blank" title="Orcid"><img alt="Orcid" class="social-icon" src="https://www.uwo.ca/web_standards/img/social/svg/icn-orcid.svg" width="32px"></a>
            &nbsp;
            <a href="/publications">Publications <i class="fa fa-external-link" aria-hidden="true"></i></a>
          </p>
 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    methods: {
      redirect_to_team: function () {
        var self = this;
        self.$router.push('/team');
      },
    },
  };
</script>
<style scoped>
  img {
    border-radius: 35px;
  }
</style>
