import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "team" */ '@/views/Team.vue'),
  },

  {
    path: '/lab-news',
    name: 'LabNews',
    component: () => import(/* webpackChunkName: "lab_news" */ '@/views/LabNews.vue'),
  },

  {
    path: '/publications',
    name: 'Publications',
    component: () => import(/* webpackChunkName: "publications" */ '@/views/Publications.vue'),
  },

  {
    path: '/collaborators',
    name: 'Collaborators',
    component: () => import(/* webpackChunkName: "collaborators" */ '@/views/Collaborators.vue'),
  },

  {
    path: '/teaching',
    name: 'Teaching',
    component: () => import(/* webpackChunkName: "teaching" */ '@/views/Teaching.vue'),
  },

  {
    path: '/funding',
    name: 'Funding',
    component: () => import(/* webpackChunkName: "funding" */ '@/views/Funding.vue'),
  },

  {
    path: '/participate',
    name: 'Participate',
    component: () => import(/* webpackChunkName: "participate" */ '@/views/Participate.vue'),
  },

  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   console.log(to, from);
//   // ...
//   // explicitly return false to cancel the navigation
//   // return false;
// });

export default router;
