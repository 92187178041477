import { createStore } from 'vuex';
import { auth } from '@/services/firebase';

export default createStore({
  state: {
    user: null,
    token: null,
    email_verified: null,
    user_roles: [],
  },
  mutations: {
    // setUser sets the user and email_verified properties if applicable
    setUser(state, payload) {
      state.user = payload;
      if (payload != null) {
        state.email_verified = state.user.emailVerified;
      }
    },
    setEmailVerified(state, payload) {
      state.email_verified = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setUserRoles(state, payload) {
      state.user_roles = payload;
    },
    cleanup(state) {
      state.user = null;
      state.token = null;
      state.email_verified = null;
      state.user_roles = [];
    },
  },
  actions: {
    async getValidToken({ commit }) {
      const user = auth.currentUser;
      // console.log('Token BEFORE:', state.token);

      if (user) {
        await user
          .getIdToken()
          .then((idToken) => {
            commit('setToken', idToken);
            // console.log('Token AFTER:', idToken);
          })
          .catch((error) => {
            console.error('Error refreshing token', error);
          });
      }
    },
  },
  modules: {},
});
