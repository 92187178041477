<template>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-yasaman">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">Yasaman Rafat</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <!-- <li class="nav-item">
            <router-link class="nav-item nav-link" to="/">Home</router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/team">Team</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/lab-news">Lab News</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/publications">Publications &amp; Presentations</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-item nav-link" to="/teaching">Teaching</router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/collaborators">Collaborators</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/funding">Funding</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/participate">Participate</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-item nav-link" to="/contact">Contact</router-link>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li> -->
        </ul>
        <!-- <router-link class="nav-item nav-link" to="/signin" v-if="!this.$store.state.user">Sign in</router-link>
        <router-link class="nav-item nav-link" to="/signup" v-if="!this.$store.state.user">Sign up</router-link>
        <router-link class="nav-item nav-link" to="#" v-if="this.$store.state.user" event @click.prevent="redirect_to_account">Account</router-link>
        <router-link class="nav-item nav-link" to="#" v-if="this.$store.state.user" event @click.prevent="logout">Logout</router-link>-->

        <!-- <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>

<script>
  // import { auth, signOut } from '@/services/firebase';

  // needed for collapse navbar
  import 'bootstrap';

  export default {
    name: 'navigation',
    props: ['project_name'],

    methods: {
      // logout: function () {
      //   var self = this;
      //   signOut(auth).then(() => {
      //     self.$router.push('/signin');
      //   });
      // },
      // redirect_to_account: function () {
      //   let account_type = this.$store.state.account_type;
      //   let current_path = this.$route.path;
      //   console.log(current_path, account_type);
      //   this.$router.push('/account');
      // },
      // bg_based_on_account_type: function(){
      //     if(this.$store.state.account_type == "expert"){
      //         return "bg-dark"
      //     }
      //     if(this.$store.state.account_type == "client"){
      //         return "bg-primary"
      //     }
      // }
    },
  };
</script>

<style scoped>
  .bg-yasaman {
    background-color: #4a0177;
    /* background-color: #441f7a; */
  }
</style>
