// // v9 compat packages are API compatible with v8 code
// // import firebase from "firebase/compat/app";
// // import "firebase/compat/auth";
// // import "firebase/compat/firestore";
// // https://stackoverflow.com/questions/68929593/vue-2-export-default-imported-as-firebase-was-not-found-in-firebase-app

// import { initializeApp } from 'firebase/app';
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
//   sendEmailVerification,
//   applyActionCode,
//   deleteUser,
//   sendPasswordResetEmail,
//   verifyPasswordResetCode,
//   confirmPasswordReset,
//   signOut,
//   onAuthStateChanged,
//   reauthenticateWithCredential,
//   EmailAuthProvider,
//   GoogleAuthProvider,
//   signInWithPopup,
// } from 'firebase/auth';
// // import "firebase/firestore";

// import { getAnalytics, logEvent } from 'firebase/analytics';

// // to be parametrized with .env file
// const config = {
//   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
//   authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
//   // databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_FIREBASE_APP_ID,
//   measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
// };

// const app = initializeApp(config);

// const auth = getAuth();
// // auth.languageCode = "en";
// // auth.languageCode = "el";

// const analytics = getAnalytics(app);

// function gaLogger(ev_name, ev_data = {}) {
//   try {
//     logEvent(analytics, ev_name, ev_data);
//   } catch (err) {
//     console.error(`Unable to log event ${ev_name} ${JSON.stringify(ev_data)}. ${err.message}`);
//   }
// }

// export {
//   auth,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
//   sendEmailVerification,
//   applyActionCode,
//   deleteUser,
//   sendPasswordResetEmail,
//   verifyPasswordResetCode,
//   confirmPasswordReset,
//   signOut,
//   onAuthStateChanged,
//   reauthenticateWithCredential,
//   EmailAuthProvider,
//   GoogleAuthProvider,
//   signInWithPopup,
//   gaLogger,
// };
